*{
  
}




#sponsorship-top-showcase{
  width:100%;
  height:86vh;
  overflow: hidden;
  opacity: 0;
  transition:1s;
  position: relative;
  /* border:1px solid red; */
}

#sponsorship-top-showcase-image{
  width:calc(100% + 50px);
  opacity:.5;
  position: relative;
  object-fit: cover;
  right:0;
  bottom: 0;
  transition:5s ease-out;
  object-position: bottom;
}

a {
  color: red;
}

#sponsorship-top-showcase-text{
  /* border: 1px solid red; */
  position: absolute;
  top:50px;
  /* top:100vh; */
  transition:1s ease-out;
  width:100%;
  max-width: 100%;
  margin:0 auto 50px;
}

#sponsorship-top-showcase h1 {
  position: relative;
  color:#fff;
  width:1000px;
  margin:0 auto;
  font-size:90px;
  text-align: center;
  font-family: 'Rajdhani', sans-serif;
}
#sponsorship-top-showcase h2 {
  width:900px;
  margin:17px auto;
  color:#eee;
  text-align: center;
  font-weight: 300;
  font-size:30px;
  padding:0;
}

.sponsorship-top-showcase-image{
  
}

.sponsorship-package-container{
  width:150px;
  height:80px;
  border:3px solid #eee;
  position: relative;
  margin:0 auto;
  z-index: 1;
  top:-5vh;
}

.sponsorship-package-container:hover {
  cursor: pointer;
}

.sponsorship-package-background{
background: #eee;
opacity: 0;
width:0%;
height:100%;
position: absolute;
left:0;
transition:.3s ease-in-out;
z-index: -1;
}

.sponsorship-package-container:hover .sponsorship-package-background{
  width:100%;
  opacity: 1;
}

.sponsorship-package-title{
  color:#fff;
  font-size: 27px;
  font-weight: 100;
  text-align: center;
  margin-top: 20px;
  z-index: 5;
  transition: .3s ease-in-out;
}

.sponsorship-package-container:hover .sponsorship-package-title{
  color:#111;
  z-index: 200;
}

.sponsorship-package-button{
  margin: 0 auto;
  display: block;
}

@media only screen and (max-width: 1500px) {

  #sponsorship-top-showcase{
    height:60vw;
  }
  
  #sponsorship-top-showcase-image{
    width:calc(100% + 50px);
  }
  
  
  
  #sponsorship-top-showcase-text{
    top:25vw;
    margin:0 auto 10vw;
  }
  
  #sponsorship-top-showcase h1 {
    width:100%;
    font-size:7vw;
  }
  #sponsorship-top-showcase h2 {
    width:70%;
    margin:1vw auto;
    font-size:2.5vw;
  }
  
  .sponsorship-top-showcase-image{
    
  }
  
  .sponsorship-package-container{
    width:12vw;
    height:6vw;
    border:2px solid #eee;
    margin:0;
    margin-left:2vw;
    z-index: 1;
    top:4vh;
  }
  
  .sponsorship-package-container:hover {
    cursor: pointer;
  }
  
  .sponsorship-package-background{
  }
  
  .sponsorship-package-container:hover .sponsorship-package-background{
  }
  
  .sponsorship-package-title{
    font-size: 2vw;
    margin-top: 1.5vw;
  }
  
  .sponsorship-package-container:hover .sponsorship-package-title{
  }
}