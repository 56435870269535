.team-members-section-name{
  color:#fff;
  font-weight:100;
  font-size:40px;
  width:1250px;
  position: relative;
  margin:0 auto;
  /* padding-bottom: 20px; */
  margin-bottom:30px;
  /* border-bottom:1px solid #333; */
}

.team-members-container{
  width:1320px;
  /* border:1px solid red; */
  display: flex;
  flex-wrap: wrap;
  position: relative;
  margin:0 auto;
  justify-content: letf;
}

.team-member-container{
  position: relative;
  width:400px;
  height:200px;
  /* border:1px solid #d42020; */
  overflow: hidden;
  margin:40px 20px;
  display: flex;
}

.team-member-image-container{
  position: relative;
  width:170px;
  height:200px;
  /* border:1px solid blue; */
}
.team-member-image{
  position: relative;
  width:100%;
  transition: 3s ease-in-out;
}
.team-member-container:hover .team-member-image{
  opacity: .9;
}

.team-member-text-container{
  position: relative;
  width:200px;
  /* border:1px solid blue; */
  padding:5px 0 0 10px;
}

.team-member-name{
color:#fff;
font-size: 25px;
font-weight: 100;
padding-bottom: 10px;
border-bottom:1px solid rgb(80, 80, 80);
}

.team-member-position{
  color:#aaa;
  font-weight: 100;
  margin-top:10px;
  font-size:20px;
}

.team-member-linkedin-button{
  margin-top:15px;
  border:2px solid rgb(98, 98, 98);
  border-radius: 5px;
  width:30px;
  height:30px;
  transition:.5s ease-in-out;
}

.team-member-linkedin-name{
  color:rgb(114, 114, 114);
  padding:1px 0 0 4px;
  font-weight: 600;
  font-size:21px;
  transition:.5s ease-in-out;
}

.team-member-linkedin-button:hover{
  cursor: pointer;
  border:2px solid rgba(54, 175, 255, 0.692);
}

.team-member-linkedin-button:hover .team-member-linkedin-name{
  color:rgb(54, 175, 255);
}


@media only screen and (max-width: 1400px) {
  *{
    /* border: 1px solid red; */
  }
  .team-members-section-name{
    font-size:3.5vw;
    width:100%;
    /* padding-left:3vw; */
    margin:0 auto;
    margin-bottom:2vw;
    text-align: center;
  }
  
  .team-members-container{
    width:87%;
    margin:0 auto;
    justify-content: center;
  }
  
  .team-member-container{
    position: relative;
    width:30vw;
    height:15vw;
    margin:3vw 1vw;
  }
  
  .team-member-image-container{
    position: relative;
    width:15vw;
  }
  .team-member-image{
  }
  .team-member-container:hover .team-member-image{
  }
  
  .team-member-text-container{
    width:16vw;
    /* border:1px solid blue; */
    padding:.5vw 0 0 .5vw;
  }
  
  .team-member-name{
  font-size: 2vw;
  padding-bottom: .7vw;
  }
  
  .team-member-position{
    margin-top:.7vw;
    font-size:1.5vw;
  }
  
  .team-member-linkedin-button{
    margin-top:.5vw;
    border:1px solid rgb(98, 98, 98);
    border-radius: 5px;
    width:3vw;
    height:3vw;
    transition:.5s ease-in-out;
  }
  
  .team-member-linkedin-name{
    color:rgb(114, 114, 114);
    padding:.3vw 0 0 .5vw;
    font-weight: 6vw;
    font-size:2vw;
    transition:.5s ease-in-out;
  }
  
  .team-member-linkedin-button:hover{
    cursor: pointer;
    border:1px solid rgba(54, 175, 255, 0.692);
  }
  
  .team-member-linkedin-button:hover .team-member-linkedin-name{
    color:rgb(54, 175, 255);
  }

}

@media only screen and (max-width: 1000px) {
  .team-member-container{
    width:40vw;
    height:18vw;
  }

  .team-member-image-container{
    width:17vw;
  }
  .team-member-linkedin-button{
    width:0;
    height:0;
    opacity:0;
  }
  
}
