.design-container{
  position: relative;
  width:100%;
  height:750px;
  margin:50px auto;
  /* border: 1px solid red; */
  /* overflow: hidden; */
  /* background:#111; */
}

#design-moving-container{
  width:700%;
  position: relative;
  left:0;
  /* border:1px solid green; */
  display: flex;
  transition:.4s ease-in-out;

  /* opacity:0; */
}
    .design-page {
      position: relative;
      width:100%;
      height:800px;
      display: flex;
      /* border:1 px solid yellow; */
      justify-content: center;
    }

      #design-text-container {
        width:450px;
        height:600px;
        /* border:1px solid green; */
        padding:80px 20px;
        position: relative;
        top:100px;

        opacity: 0;
        transition: .6s;
      }
      .design-text-container {
        width:450px;
        height:600px;
        padding:80px 20px;
        position: relative;
      }

        .design-text-car{
          color:#999;
          font-weight: 100;
          font-size:25px;
          padding-left:5px;
          /* border:1px solid blue */
        }

        .design-text-title{
          color:#ddd;
          font-size:50px;
          /* border:1px solid blue; */
          margin:5px 0 30px;
        }

        .design-text-para{
          /* border:1px solid blue; */
          font-size:20px;
        font-weight:500;
        color:#aaa;
        text-align: justify;
        line-height: 1.5em;
        }



      .design-image-container {
        position: relative;
        width:750px;
        height:500px;
        margin-top:70px;
        /* border:1px solid red; */
      }

        #design-image-cartoon{
          width:100%;
          position: absolute;
          opacity: 1;
          transition:4s;
        }

        #design-image {
          width:100%;
          position: absolute;
          opacity: 0;
          transition: 5s;
        }
        .design-image {
          width:100%;
          position: absolute;
          opacity: 1;
          transition: 4s;
        }






.left-buttom{
  position: absolute;
  left:10px;
  bottom:0px;
  width:80px;
  height:80px;
  rotate: 90;
  /* border:1px solid blue; */
  opacity:50%;
  transition: 1s;
}

.left-buttom:hover {
  opacity: 1;
  transition: .4s;
  cursor: pointer;
}

.left-arrow{
  width:100%;
  transform: rotate(270deg)
}

.right-buttom{
  position: absolute;
  right:10px;
  bottom:0px;
  width:80px;
  height:80px;
  rotate: 90;
  /* border:1px solid blue; */
  opacity:50%;
  transition: 1s;
}

.right-buttom:hover {
  opacity: 1;
  transition: .4s;
  cursor: pointer;
}

.right-arrow{
  width:100%;
  transform: rotate(90deg)
}

.design-selector {
  position: absolute;
  width:720px;
  height:50px;
  bottom:20px;
  right:calc(50vw - 300px);
  /* background-color:red; */
  display: flex;

}

  .design-selection-container {
    width:150px;
    justify-content: center;
    /* border:1px solid blue; */
    padding:10px;
  }
  .design-selection-container:hover{
    cursor: pointer;
  }


    .design-selection-name{
      color:#000;
      font-size:15px;
      width:100px;
      height:30px;
      text-align: center;
      transition:.5s ease;
      /* border:1px solid green; */
    }
    .design-selection-container:hover .design-selection-name{
      color:#999
    }

    .design-selection-bar {
      background-color:#555;
      height:2px;
      width:auto;
      transition:.5s ease;
      cursor: pointer;
    }
    .design-selection-container:hover .design-selection-bar {
      background-color:#fff;
      height:2px;
    }
    .selected-bar{
      background-color:#fff;
      height:2px;
    }


  @media only screen and (max-width: 1200px) {

    .design-container{
      width:100%;
      height:55vw;
      margin:4vw auto;
    }
    
    #design-moving-container{
    }
        .design-page {
          width:100%;
          height:100%;
        }
    
          #design-text-container {
            width:35vw;
            height:100%;
            padding:5vw 1vw;
            position: relative;
            top:10vw;
    
          }
          .design-text-container {
            width:100%;
            height:100%;
            padding:5vw 1vw;
          }
    
            .design-text-car{
              font-size:2vw;
              padding-left:1vw;
            }
    
            .design-text-title{
              font-size:4vw;
              margin:2vw 0 2vw;
            }
    
            .design-text-para{
              font-size:1.7vw;
            }
    
          #design-image-container {
            width:65vw;
            height:100%;
            margin-top:6vw;
          }
    
            #design-image-cartoon{
              width:100%;
            }
    
            #design-image {
              width:100%;
            }
            .design-image {
              width:100%;
            }
    
    
    
    
    
    
    .left-buttom{
      position: absolute;
      left:1vw;
      width:6vw;
      height:6vw;
    }
    
    .left-buttom:hover {
    }
    
    .left-arrow{
    }
    
    .right-buttom{
      position: absolute;
      right:1vw;
      width:6vw;
      height:6vw;
    }
    
    .right-buttom:hover {
    }
    
    .right-arrow{
    }
    
    .design-selector {
      position: absolute;
      width:calc(70vw);
      height:5vw;
      bottom:2vw;
      right:calc(15vw);
      /* background-color:red; */
      /* display: flex; */
    
    }
    
      .design-selection-container {
        width:10vw;
        /* border:1px solid blue; */
        padding:1vw;
      }
      .design-selection-container:hover{
      }
    
    
        .design-selection-name{
          font-size:1vw;
          width:7vw;
          height:2vw;
          /* border:1px solid green; */
        }
        .design-selection-container:hover .design-selection-name{
        }
    
        .design-selection-bar {
          height:1px;
        }
        .design-selection-container:hover .design-selection-bar {
          background-color:#fff;
          height:1px;
        }
        .selected-bar{
          background-color:#fff;
          height:1px;
        }
  }


