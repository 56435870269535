.footer-container{
  width:100%;
  height:400px;
  padding-top:100px;
  /* border:1px solid red; */

}

.contact-us-button{
  border:1px solid #ddd;
  position: relative;
  margin:10px auto;
  width:250px;
  height:80px;
  opacity: .9;
  transition: .5s ease-in-out;
}
.contact-us-button:hover {
  cursor: pointer;
  opacity: 1;
}

.contact-us-title{
  width:100%;
  text-align: center;
  padding-top:22px;
  color:#fff;
  font-weight:100;
  font-size:27px;
  transition: .4s ease-in-out;
  z-index: 2;
}
.contact-us-button:hover .contact-us-title{
  color:#000;
}

.contact-us-white-background{
  background: rgb(221, 221, 221);
  height:100%;
  position: absolute;
  left:0;
  width:0;
  transition:.3s ease-in-out;
  z-index: -1;
}
.contact-us-button:hover .contact-us-white-background{
  width:100%;
}
.footer-white-line{
  background: #ddd;
  width:2px;
  height:40px;
  position: relative;
  margin:40px auto;
}

.social-media-name-container{
  width:300px;
}
.social-media-name-container h1{
  font-size:24px;
  color:#777;
}

.footer-social-media-container{
  position: relative;
  height:100px;
  width:900px;
  margin:50px auto;
  /* border:1px solid red; */
  display: flex;
}
.social-media-item{
  position: relative;
  width:auto;
  padding:10px;
  margin:0 30px;
  /* border:1px solid blue; */
}
.social-media-name{
  color:#fff;
  text-decoration: none;
  padding:5px;
  font-size:20px;
  font-weight: 100;
  width: 100%;
  
}

.social-media-underline{
  position: relative;
  height:1px;
  margin-top: 5px;
  width:0;
  left:0;
  background: rgb(255, 255, 255);
  transition:.3s;
}
.social-media-item:hover .social-media-underline{
  width: 100%;
}


@media only screen and (max-width: 1200px) {
  .footer-container{
    width:100%;
    height:32vw;
    padding-top:3vw;
  }
  
  .contact-us-button{
    margin:.5vw auto;
    width:20vw;
    height:7vw;
  }
  .contact-us-button:hover {
  }
  
  .contact-us-title{
    width:100%;
    padding-top:1.9vw;
    font-size:2.4vw;
  }
  .contact-us-button:hover .contact-us-title{
  }
  
  .contact-us-white-background{
  }

  
  .footer-white-line{
    background: #ddd;
    width:1px;
    height:2vw;
    position: relative;
    margin:2vw auto;
  }
  
  .social-media-name-container{
    width:100%;
  }
  .social-media-name-container h1{
    font-size:2vw;
  }
  
  .footer-social-media-container{
    height:10vw;
    max-width:90vw;
    margin:3vw auto;
    /* border:1px solid red; */
    display: flex;
  }
  .social-media-item{
    padding:1vw;
    margin:0 2vw;
    /* border:1px solid blue; */
  }
  .social-media-name{
    font-size:2vw;
    
  }
  
  .social-media-underline{
  }
  .social-media-item:hover .social-media-underline{
  }
  
}