
$textColor:#fff;
$lightFontWeight:100;

#cross-view-container{
  position: relative;
  // border:1px solid red;
  width:100%;
  height:100vh;
  display: flex;
  opacity:0;
  transition:2s ease-out;
}

#cross-view-text-container{
  // border:1px solid blue;
  margin-top:200px;
  width:55%;
  padding: 90px 0 0 100px;
  transition:2s ease-out;
  
}

.cross-view-title{
  color:$textColor;
  font-size:70px;
  // border:1px solid red;
}

.cross-view-text{
  color:$textColor;
  font-weight: $lightFontWeight;
  line-height: 1.5em;
  margin-top:40px;
  text-align: justify;
}

#cross-view-image{
  object-fit: cover;
  position: relative;
  height:100%;
  left:50px;
  transition:2s ease-out;
}

@media only screen and (max-width: 1500px) {


  #cross-view-container{
    height:55vw;
  }
  
  #cross-view-text-container{
    margin-top:0vw;
    width:55%;
    padding: 1vw 0 0 2vw;
  }
  
  .cross-view-title{
    color:$textColor;
    font-size:6vw;
    
  }
  
  .cross-view-text{
    margin-top:3vw;
    font-size:1.7vw;
  }
  
  #cross-view-image{
    left:50px;
    transition:2s ease-out;
  }  
}

@media only screen and (max-width: 600px) {


  #cross-view-container{
    height:55vw;
  }
  
  #cross-view-text-container{
    margin-top:0vw;
    width:75%;
    padding: 1vw 0 0 2vw;
  }
  
  .cross-view-title{
    color:$textColor;
    font-size:6vw;
    
  }
  
  .cross-view-text{
    margin-top:3vw;
    font-size:2.5vw;
  }
  
  #cross-view-image{
    height:90%;
    left:50px;
    transition:2s ease-out;
  }  
}