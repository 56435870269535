.landing-ether{
    height:calc(100vh + 240px);
  }
  
  #landing-page-container {
    width: 100%;
    height:100vh;
    position: fixed;
    top:0;
  }
  
    #landing-image-container {
      width:65%;
      max-width:800px;
      height:100%;
      position: relative;
      margin:0px auto;
      top:100px;
      justify-content: center;
      opacity: 0;
      transition:3s ease;
    }
  
      #landing-image{
         width:100%;
         opacity: 1;
         position: absolute;
         top:20px;
      }
      #landing-image-dark{
        width:100%;
        
        opacity: 1;
        position: absolute;
        top:20px;
        transition: 3s;
     }
  
  
  
      #landing-title-container {
        width:1200px;
        position: absolute;
        justify-content: center;
        top:400px;
        margin:0 calc(50vw - 600px);
  
        opacity: 0;
        transition: 1s;
      }
  
      .landing-title {
        color:#fff;
        margin:0 auto;
        font-size:100px;
        text-align: center;
        font-family: 'Rajdhani', sans-serif;
        font-weight: bold;
      }
  
      #landing-subtitle{
        color:#fff;
        margin-top:40px;
        font-size:32px;
        text-align: center;
        transition: 1s;
        font-weight: lighter;
      }
   
    .enter-arrow-container {
      position: absolute;bottom:0px;
      /* border: 1px solid red; */
      width:60px;
      height:120px;
      margin-left: calc(50vw - 30px);
      transition: .5s;
      opacity:0.7
    }
    .enter-arrow-container:hover{
      width:70px;
      margin-left: calc(50vw - 35px);
      opacity:1;
    }
      #enter-arrow {
        width:100%;
        transform: rotate(180deg);
        opacity: 0;
        position: relative;
        top:-30px;
        transition: 3s;
      }
      #enter-arrow:hover{
        top:40px;
        cursor: pointer;
        opacity: 1;
      }



@media only screen and (max-width: 1200px) {
  .landing-ether{
  }
  
  #landing-page-container {
  }
  
    #landing-image-container {
      width:100%;
      height:100%;
      position: relative;
      margin:0px auto;
      top:calc(50vh - 30vw);
    }
      #landing-image{
         top:2vw;
      }
      #landing-image-dark{
        top:2vw;
     }
  
  
  
      #landing-title-container {
        width:1200px;
        position: absolute;
        justify-content: center;
        top:400px;
        margin:0 calc(50vw - 600px);
  
        opacity: 0;
        transition: 1s;
      }
  
      .landing-title {
        color:#fff;
        margin:0 auto;
        font-size:100px;
        text-align: center;
        font-family: 'Rajdhani', sans-serif;
        font-weight: bold;
      }
  
      #landing-subtitle{
        color:#fff;
        margin-top:40px;
        font-size:32px;
        text-align: center;
        transition: 1s;
        font-weight: lighter;
      }
   
    .enter-arrow-container {
      position: absolute;bottom:0px;
      /* border: 1px solid red; */
      width:60px;
      height:120px;
      margin-left: calc(50vw - 30px);
      transition: .5s;
      opacity:0.7
    }
    .enter-arrow-container:hover{
      width:70px;
      margin-left: calc(50vw - 35px);
      opacity:1;
    }
      #enter-arrow {
        width:100%;
        transform: rotate(180deg);
        opacity: 0;
        position: relative;
        top:-30px;
        transition: 3s;
      }
      #enter-arrow:hover{
        top:40px;
        cursor: pointer;
        opacity: 1;
      }
}



/* mobile showcase */
@media only screen and (max-width: 750px) {
  .landing-mobile{
    width:90%;
    margin:0 auto;
    height:100vh;
  }
  .mobile-showcase-pic{
    object-fit: contain;
    width:100%;
    margin-top:calc(50vh - 60vw);
  }
  #landing-title-container{
    position: absolute;
    /* border:1px solid red; */
    width:100%;
    margin:0;
    left:0;
  }
  .landing-title{
    font-size:10vw;
  }
  #landing-subtitle{
    font-size:4vw;
  }

  #mobile-landing-title-container{
    /* border:1px solid red; */
    position: relative;
    top:-24vh;
  }

  .mobile-landing-title{
    

    font-family: 'Rajdhani', sans-serif;
    color:#fff;
    font-size:9vw;
    text-align:center;
  }

  #mobile-landing-subtitle{
    color:#fff;
    font-size:3vw;
    font-weight: 100;
    text-align: center;
  }
}