#instagram-container{
  margin-top:100px;
  width:100%;
  height:600px;
  // border:1px solid red;
  position: relative;
  opacity: 0;
  transition:2s ease-out;
}

.instagram-title{
  position: relative;
  color:#aaa;
  font-size:30px;
  width:290px;
  margin:0 auto;
  text-align: center;
  font-weight: 100;
  padding:10px 0;
  transition:.5s ease-in-out;
}
.instagram-title:hover{
  color:#fff;
}
.instagram-underline{
  height:1px;
  width:0%;
  position: absolute;
  bottom:0;
  background: linear-gradient(to left, rgb(225, 0, 255), rgb(245, 230, 16));
  transition:.4s ease-in-out;
}
.instagram-title:hover .instagram-underline{
  width:100%;
}

.instagram-post-container{
  width:1400px;
  height:400px;
  position: relative;
  margin:40px auto;
  // border:1px solid blue;
  overflow: hidden;
  display: flex;
  justify-content: center;
}

.instagram-post{
  width: 400px;
  height: 400px;
  object-fit: cover;
  // border:1px solid blue;
  overflow: hidden; 
  margin-left:30px;
  margin-right:auto;
  opacity: .5;
  transition: .6s ease-in-out;
}
.instagram-post:hover{
  cursor: pointer;
  opacity: .9;
}
.instagram-image{
  position: relative;
  object-fit: cover;
  height:100%;
}


@media only screen and (max-width: 1500px) {
  #instagram-container{
    margin-top:7vw;
    width:100%;
    height:40vw;
    // border:1px solid red;
  }
  
  .instagram-title{
    font-size:2vw;
    width:20vw;
    padding:.5vw 0;
    transition:.5s ease-in-out;
  }
  .instagram-title:hover{
  }
  .instagram-underline{
  }
  .instagram-title:hover .instagram-underline{
  }
  
  .instagram-post-container{
    width:100%;
    height:30vw;
    margin:3vw 0;
    // border:1px solid blue;
    overflow: hidden;
    display: flex;
    justify-content: center;
  }
  
  .instagram-post{
    width: 30vw;
    height: 30vw;
    object-fit: cover;
    // border:1px solid blue;
    overflow: hidden; 
    // margin-left:30px;
    // margin-right:auto;
    opacity: .5;
    transition: .6s ease-in-out;
  }
  .instagram-post:hover{
    cursor: pointer;
    opacity: .9;
  }
  .instagram-image{
    position: relative;
    object-fit: cover;
    height:100%;
  }
}