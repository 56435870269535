#team-showcase-container {
  width: 100%;
  height: 700px;
  /* overflow: hidden; */
  opacity: 0;
  transition: 1s;
}

#team-showcase-image {
  width: calc(100% + 30px);
  left: 30px;
  height: 77vh;
  transition: 4s ease-out;
  object-fit: cover;
  opacity: .6;
  position: relative;
}

.team-showcase-gradient {
  position: absolute;
  width: 100%;
  height: 50%;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(rgba(255, 0, 0, 0), rgb(0, 0, 0));
  border: 1px solid red;
}

#team-showcase-text-container {
  position: relative;
  width: 800px;
  margin: 0 auto;
  top: -35vh;
  transition: 1s;
  /* border:1px solid red; */
}

.team-showcase-text {
  color: #fff;
  font-size: 80px;
  text-align: center;
  font-family: 'Rajdhani', sans-serif;
}

.team-showcase-blurb {
  color: #ddd;
  font-size: 27px;
  line-height: 1.4em;
  font-weight: 100;
  text-align: center;
}

.recruitment-link-container {
  margin: 20px auto;
  width: 250px;
  left: 200;
  /* border: 1px solid red; */
}



@media only screen and (max-width: 1200px) {

  #team-showcase-container {
    height: 65vw;
  }

  #team-showcase-image {
    width: calc(100% + 30px);
    left: 30px;
    height: 100%;
  }

  .team-showcase-gradient {
    position: absolute;
    width: 100%;
    height: 50%;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(rgba(255, 0, 0, 0), rgb(0, 0, 0));
  }

  #team-showcase-text-container {
    position: relative;
    width: 75vw;
    top: -35vh;
    transition: 1s;
    /* border:1px solid red; */
  }

  .team-showcase-text {
    font-size: 7vw;
  }

  .team-showcase-blurb {
    font-size: 2.4vw;
  }

}