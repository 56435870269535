#instagram-showcase-container{
  width:450px;
  height:400px;
  position: absolute;
  top:240px;
  right:60px;
  opacity:0;
  transition:2s ease-out;
  // border:1px solid blue;
}

#instagram-showcase-container div{
  position: absolute;
  border:1px solid rgba(146, 146, 146, 0.267);
  overflow: hidden;
  border-radius: 10px;
  transition:.5s ease-in-out;
  top:40px;
  left: 50px;
  width:350px;
  height:350px;
}

.instagram-showcase-post-1{opacity:0;}
#instagram-showcase-container:hover .instagram-showcase-post-1{
  top:0px;
  left:0px;
width:300px;
height:300px;
opacity: 1;
}
.instagram-showcase-post-2{opacity:0;}
#instagram-showcase-container:hover .instagram-showcase-post-2{
  position: absolute;
  top:16px;
  left:23px;
  width:320px;
  height:320px;
  opacity: 1;
}

#instagram-showcase-container:hover .instagram-showcase-post-3{
  position: absolute;
  top:40px;
  left: 50px;
  width:350px;
  height:350px;
  opacity: 1;
}

.instagram-showcase-image{
object-fit: cover;
width:100%;
height:100%;
opacity:.4;
transition: .3s ease-in-out;

}


.instagram-showcase-image:hover{
  opacity: .5;
  cursor: pointer;
}