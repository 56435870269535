#photoslide-container-2{
  width:100vw;
  height:100vh;
  margin:50px auto;
  padding:40px 0;
  /* border:2px solid blue; */
  position:relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  opacity:0;
  transition: .5s ease-in-out;
}

  .white-line{
    position: relative;
    /* border:1px solid green; */
    width:1300px;
    height:40px;
  }
  .white-line div{
    background: #aaa;
    width:2px;
    height:100%;
    margin: 0 auto
  }

  #description-container-2{
    position: relative;
    width: 500px;
    height:500px;
    top:150px;
    /* top:0; */
    transition: .6s;
    /* border: 1px solid blue; */
  }

    #photoslide-title-2{
      color:#eee;
      width:100%;
      font-size:65px;
      text-align: left;
      padding:20px 0 0 0px;

    }

    #photoslide-description-2{
      color:#eee;
      font-size:18px;
      font-weight: 100;
      line-height: 1.6em;
      padding:20px 0px;
      text-align:justify;
    }

  .photoslide-image-container-2{
    position: relative;
    width:880px;
    height:500px;
    margin-right:80px;
    margin-top:10px;
    /* border:1px solid red; */
    overflow: hidden;
    /* align-content: center; */
  }

    .photoslide-image-2{
      width:100%;
      opacity:.9;
    }

.team-link-button{
  position: relative;
  width:200px;
  height:70px;
  border:1px solid #aaa;
  margin-top:30px;
}
.team-link-button:hover{
  cursor: pointer;
}
.team-link-title{
  font-size: 27px;
  color:#fff;
  text-align:center;
  font-weight: 200;
  padding-top:16px;
  transition: .4s ease-in-out;
}
.team-link-button:hover .team-link-title{
  color:#222;
}
.team-link-white{
  position: absolute;
  width:0;
  height:100%;
  top:0;
  background-color: #fff;
  transition: .4s ease-in-out;
  z-index: -1;
}
.team-link-button:hover .team-link-white{
  width:100%;
}

@media only screen and (max-width: 1500px) {
  #photoslide-container-2{
    width:100vw;
    height:60vw;
    margin:5vw auto;
    padding:4vw 0;
  }
  
    .white-line{
      width:1300px;
      height:4vw;
    }
    .white-line div{
    }
  
    #description-container-2{
      width: 40vw;
      height:30vw;
    }
  
      #photoslide-title-2{
        font-size:5vw;
      }
  
      #photoslide-description-2{
        font-size:1.2vw;
      }
  
    .photoslide-image-container-2{
      width:50vw;
      height:30vw;
      margin-right:3vw;
      margin-top:7vw;
    }
  
      .photoslide-image-2{
      }
  
  .team-link-button{
    position: relative;
    width:16vw;
    height:5vw;
    border:1px solid #aaa;
    margin-top:1vw;
  }
  .team-link-button:hover{
    cursor: pointer;
  }
  .team-link-title{
    font-size: 2vw;
    padding-top:1.2vw;
  }
  .team-link-button:hover .team-link-title{
  }
  .team-link-white{
  }
  .team-link-button:hover .team-link-white{
  }
}

@media only screen and (max-width: 500px) {
  #photoslide-container-2{
    width:100vw;
    height:50vh;
    margin:5vw auto;
    padding:4vw 0;
    display: flex;
    flex-wrap: wrap;
  }
  
    .white-line{
      width:1300px;
      height:4vw;
    }
    .white-line div{
    }
  
    #description-container-2{
      width: 80vw;
      height:50vw;
    }
  
      #photoslide-title-2{
        font-size:5vw;
      }
  
      #photoslide-description-2{
        font-size:2.5vw;
      }
  
    .photoslide-image-container-2{
      width:80vw;
      height:30vw;
      margin-right:3vw;
      margin-top:4vw;
    }
  
      .photoslide-image-2{
      }
  
  .team-link-button{
    position: relative;
    width:16vw;
    height:5vw;
    border:1px solid #aaa;
    margin-top:1vw;
  }
  .team-link-button:hover{
    cursor: pointer;
  }
  .team-link-title{
    font-size: 2vw;
    padding-top:.8vw;
  }
  .team-link-button:hover .team-link-title{
  }
  .team-link-white{
  }
  .team-link-button:hover .team-link-white{
  }
}