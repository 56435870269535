#header-container {
    width:100%;

    margin:0 auto;
    align-content: center;
    position: absolute;
    z-index: 500; 
    opacity: 1;
    transition: 1.5s;
    /* border: 1px solid red; */
}

#header  {
    display: flex;
    position: fixed;
    top:-0px;
    transition:top 5s ease-in-out;
    width: 100%;
    /* max-width:1200px; */
    height: 85px;
    color:#fff;
    margin:0 auto;
    transition: color 2s ease;
    transition: .5s ease;
    opacity: .98;
    /* background: #000; */
    transition: .5s ease-in-out;
}
#static-header {
    display: flex;
    position: relative;
    /* color: #222; */
    /* background-color: #fff; */
    /* box-shadow: 3px 3px 5px rgb(180, 180, 180); */
    width: 100%;
    max-width:1400px;
    height: 85px;
}
.tracker-backgound{
    width: 100%;
    height: 85px;  
    position: relative;
}

.top-buffer {
    position: relative;
    width:100%;
    height:70px;
    /* background-color:#fff; */
}

.logo {
    flex: 3;
    height: 100%; 
    padding-left:50px;
}

.logo-image {
    height: 80px;
    padding-left: 0%; 
    float: left;
    transition: all 0s ease;
}

.logo-text {
    position: relative;
    float: left;
    padding-top: 30px;
    padding-left:10px;
    color:inherit;
    font-size: 20px;
    font-size: 25px;
    font-weight:300;
    text-decoration: none;
    font-family: 'Rajdhani', sans-serif;
    transition: all 0s ease;
}

.nav-bar {
    flex: 1;
    display: flex;
    list-style-type: none;
    margin: 22px 10px;
    padding: 10px; 
    margin-right: 40px;
    transition: all 0s ease;
}

.nav-bar-item {
    flex: 1;
    float: right;
    margin-right: 1px; 
    transition: all 0s ease;
    
}
.nav-bar a:hover {
    color: #fff;
    transition:.5s ease;
}
.nav-bar-item a {
    width:100px;
    color:inherit;
    padding: 14px 25px;
    text-decoration: none; 
    font-size:18px;
    font-family: 'Rajdhani', sans-serif;
    transition: all 0s ease;
    
}

.white-hover-bar{
    width:0%;
    margin-left:10%;
    height:1px;
    background: rgb(230, 230, 230);
    transition:.5s;
}

.nav-bar li:hover .white-hover-bar {
    width:80%;
}

li.dropdown {
    display: inline-block; 
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    margin-top:15px; 
}

.dropdown-content a {
    color: black;
    background-color: #f9f9f9;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    text-align: left;
    border-radius: 1px; 
}

.dropdown-content a:hover {
    background-color: #f1f1f1; 
}

.dropdown:hover .dropdown-content {
    display: block; 
}

#toggle {
    position: absolute;
    right:35px;
    width:50px;
    height:70px;
    cursor: pointer;
    opacity: 0;  
    z-index: 5;
}

.nav-bar-item-donate-button a {
    width:100px;
    color: rgb(255, 255, 255);
    background-color: rgb(228, 47, 47);
    padding: 12px 20px;
    border: 1px rgb(146, 146, 146) solid;
    border-radius: 30px;
    text-decoration: none; 
    font-weight: normal;
}
.nav-bar-item-donate-button:hover a{
    color: rgb(255, 255, 255);
    background-color: rgb(124, 0, 0);
    border: 1px #fff solid;
    text-decoration: none; 
    /* // box-shadow: 3px 3px 3px 0px grey; */
}


@media only screen and (max-width: 950px) {
    .logo-text {
        font-size:0px;
    }
}

@media only screen and (max-width: 800px) {


    * {
        transition: all .4s ease;
    }
    .logo-text {
        font-size:0px;
    }
    #static-header {
        height:70px;
        /* box-shadow: 1px 1px 1px #bbb */
    }
    .logo {
        padding-left:25px;
    }
    .logo-image {
        height: 70px;
    }

    .hamburger {
        margin-top: 5px;
        margin-right:40px;
        height:40px;
        width:35px;
        transition: all 0.4s ease;
        cursor: pointer;
    }
    .hamburger > div {
        position: relative;
        width:100%;
        height: 4px;
        margin-top:24.5px;
        background-color:#666;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
    .hamburger >div:before,
    .hamburger >div:after {
        content: "";
        position:absolute;
        z-index: 1;
        top:-12px;
        width:100%;
        height:4px;
        background: inherit;
    }
    .hamburger > div:after {
        top: 14px;
        height: 4px;
    }

    .nav-bar-item {
        display:block;
        width:100%;
        margin:0;
        background-color: rgb(0, 0, 0);
        height:60px;
    }
    .nav-bar a {
        width:100%;
        margin:0 ;
        line-height: 31px;
        display: block;
        border: 1px solid rgb(0, 0, 0);
        color:#fff;
        border-radius: 0;
    }
    .nav-bar a:hover {
        background-color: rgb(0, 0, 0);
    }

    .nav-bar {
        position: absolute;
        top:60px;
        text-align:center;
        width:100%;
        display:none;
        margin:0;
        padding:0;
    }
    #toggle:checked ~ .nav-bar{
        display: block;
        -webkit-animation-name: slide-ins;
        -webkit-animation-duration: .5s;
        z-index: -1;
        margin-top:10px;
        /* // -webkit-animation-name: fade-in;
        // -webkit-animation-duration: 2s;
        // animation: slideIn 0.6s cubic-bezier(0.54, 0.46, 0.54, 0.94) 2s forwards;
        // animation: fadeIn 0s ease-in 0s forwards;} */
    }
    
        @keyframes fade-in {
          from {opacity: 0;}
          to {opacity: 1;}
        }
     
        @keyframes slide-ins {
          from {top:-500px;
            opacity: .5;
          }
          to{
              opacity: 1;
          }
        }
      
    

    #toggle:checked + .hamburger > div{
        transform: rotate(225deg);
    }
    #toggle:checked + .hamburger >div:before,
    #toggle:checked + .hamburger > div:after {
        top: 0;
        transform:rotate(90deg);
    }

    .nav-bar-item-donate-button a {
        color: rgb(255, 255, 255);
        background-color: rgb(253, 60, 60);
        padding: 12px 20px;
        border: 1px rgb(146, 146, 146) solid;
        text-decoration: none; 
        font-weight: normal;
        position: relative;
        bottom:-15px;
        z-index:-1;
    }
    .nav-bar-item-donate-button:hover a{
        color: rgb(255, 255, 255);
        background-color: rgb(124, 0, 0);

    }
}
