#competition-showcase-container{
  width:100%;
  /* border:1px solid red; */
  height:auto;
  position: relative;
  opacity: 0;
  transition: .6s ease-in-out;
}

#competition-image {
  position: relative;
  width:calc(100vw + 50px);
  object-fit: fill;
  left:50px;
  opacity:.7;
  transition:7s ease-in-out;
}
#competition-text-container {
  position: absolute;
  top:250px;
  transition:1.4s ease-in-out;
/* border:1px solid green; */
margin-left:calc(50vw - 720px);
}
.competition-title{
  color:#fff;
  font-size:70px;
  
}
.competition-text{
  color:#fff;
  margin-top:40px;
  line-height: 1.6em;
  width:570px;
  font-size:23px;
  font-weight: 100;
}
.competition-text span{
  font-weight:700;
}

.gradient-container{
  position: absolute;
  width:70%;
  height:100%;
  left:0;
  top:0;
  background-image: linear-gradient(to left, rgba(0, 0, 0, 0), rgb(0, 0, 0));
  /* border:1px solid red; */
}

.sponsorship-button{
  border: 1px solid #fff;
  width:300px;
  height:90px;
  margin-top:70px;

  position: relative;
}
.sponsorship-button:hover {
  cursor: pointer;
}

.sponsorship-button-text{
position: relative;
  color:#fff;
  text-align: center;
  padding-top:20px;
  font-weight: 100;
  transition: .5s ease-in-out;
}

.sponsorship-button:hover .sponsorship-button-text{
  color:#000;
  z-index: 2;
}

.sponsorship-button-white{
  position: absolute;
  height:100%;
  width:0;
  top:0;
  left:0;
  background:#fff;
  z-index: 1;
  transition:.3s ease-in-out;
}
.sponsorship-button:hover .sponsorship-button-white{
  width: 100%;
}

@media only screen and (max-width: 1600px) {
  *{
    /* border:1px solid red; */
  }
  #competition-showcase-container{
  }
  
  #competition-image {
    width:calc(100vw + 50px);
  }
  #competition-text-container {
    top:10vw;
  /* border:1px solid green; */
  margin-left:5vw;
  }
  .competition-title{
    font-size:6vw;
    
  }
  .competition-text{
    color:#fff;
    margin-top:3vw;
    line-height: 1.6em;
    width:40vw;
    font-size:1.7vw;
  }
  .competition-text span{
  }
  
  .gradient-container{
  }
  
  .sponsorship-button{
    border: 1px solid #fff;
    width:25vw;
    height:7vw;
    margin-top:3vw;
  }
  .sponsorship-button:hover {
  }
  
  .sponsorship-button-text{
    padding-top:1.6vw;
    font-size:2.5vw;
  }
  
  .sponsorship-button:hover .sponsorship-button-text{
  }
  
  .sponsorship-button-white{
  }
  .sponsorship-button:hover .sponsorship-button-white{
  }
}

@media only screen and (max-width: 600px) {

#competition-showcase-container{
}

#competition-image {
  width:calc(100vw + 50px);
}
#competition-text-container {
  top:3vw;
/* border:1px solid green; */
  margin-left:5vw;
}
.competition-title{
  font-size:5vw;
  
}
.competition-text{
  color:#fff;
  margin-top:2vw;
  line-height: 1.6em;
  width:40vw;
  font-size:2.5vw;
}
.competition-text span{
}

.gradient-container{
}

.sponsorship-button{
  border: 1px solid #fff;
  width:25vw;
  height:7vw;
  margin-top:3vw;
}
.sponsorship-button:hover {
}

.sponsorship-button-text{
  padding-top:1.8vw;
  font-size:2.5vw;
}

.sponsorship-button:hover .sponsorship-button-text{
}

.sponsorship-button-white{
}
.sponsorship-button:hover .sponsorship-button-white{
}
}