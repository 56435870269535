// *{border:1px solid red;}

#powered-by-container{
  // border:1px solid red;
  position: absolute;
  bottom:calc(50vh - 220px);
  left:calc(50vw - 550px);
  width:500px;
  height:450px;
  opacity:0;
  transition:1s ease-out;
  padding:10px;
  // overflow: hidden;
}

.powered-by-title{
  color:rgb(224, 224, 224);
  padding:40px 20px;
  margin-bottom:5px;
  font-size: 40px;
  position: relative;
  width:288px;
  height:130px;
  font-weight: 100;
}
#view-all-text{
  font-size:20px;
  color:rgb(92, 92, 92);
  width:200px;
  position: relative;
  top:15px;
  // left:20px;
  font-weight: 100;
  padding:0;
  transition:.3s ease-in-out;
}
#view-all-text:hover{
  color:#fff;
}

.powered-by-sponsors-container{
  position: relative;
  display: flex;
  flex-wrap: wrap;
}

.powered-by-sponsors-container a{
width:130px;
height:130px;
margin:7px;
border-radius: 5px;
overflow: hidden;
// border:2px solid;
opacity:0;
transition:1s ease-out;
// background-color: #fff;
}

.powered-by-sponsor-image{
  width:100%;
  height:100%;
  object-fit: contain;
  background-color: #fff;
  opacity:.9;
  transition:.3s ease-in-out;
}
.powered-by-sponsor-image:hover{
  opacity:1;
}

.powered-by-view-all-text{
  color:#fff;
  font-size:20px;
  width:100px;
  font-weight: 100;
  padding:20px;
}


@media only screen and (max-width: 1200px) {

#powered-by-container{
  bottom:calc(50vh - 30vw);
  left:2vw;
  width:50vw;
  height:50vw;
}

.powered-by-title{
  color:rgb(224, 224, 224);
  padding:4vw 2vw;
  margin-bottom:5px;
  font-size: 4vw;
  position: relative;
  width:30vw;
  height:10vw;
}
#view-all-text{
  font-size:2vw;
  width:20vw;
  top:2vw;
}
#view-all-text:hover{
}

.powered-by-sponsors-container{
}

.powered-by-sponsors-container a{
width:14vw;
height:14vw;
margin:.5vw;
border-radius: 1vw;
}

.powered-by-sponsor-image{
}
.powered-by-sponsor-image:hover{
}

.powered-by-view-all-text{
  font-size:2vw;
  padding:1vw;
}
}