#static-scrolling-container{
  width:100%;
  height: calc(100vh + 1200px);
  opacity: 0;
  transition: 2s ease-in-out;
}

#main-showcase-container {
  /* border:1px solid red; */
  width:1200px;
  height:100%;
  margin: 0 calc(50vw - 600px);
  display:flex;
  position: fixed;
}

  .image-container{
    position: relative;
    left:-150px;
    /* right:300px; */
    width:750px;
    height:700px;
    margin-top:140px;
    /* border:2px solid red; */
  }

    #image-full {
      position: absolute;
      width:750px;
      max-width:750px;
      min-width:437px;
      height:700px;
      /* border:2px solid blue; */
      background: url('./../../../img/renders/another-full.png');
      background-size: cover;
    }

    .image-cut{
      position: absolute;
      width:750px;
      height:700px;
      /* border:2px solid blue; */
      background: url('./../../../img/renders/newer-cut.png');
      background-size: cover;
    }


  #text-container{
    position: absolute;
    right:10px;
    width:600px;
    height:500px;
    min-height:500px;
    max-height:2000px;
    top:360px;
    transition: 2s;
    /* margin-top:340px; */
    /* border:2px solid green; */
  }

    .car-description-title{
      color:#fff;
      font-size:60px;
      padding-bottom:20px;
    }
    .car-description{
      font-size:25px;
      font-weight:500;
      color:#aaa;
      text-align: justify;
      line-height: 1.5em;
    }

  #main-specs {
    position: absolute;
    width:0px;
    opacity:0;
    max-width:600px;
    height:500px;
    right:30px;
    top:200px;
    color:#fff;
    /* border:2px solid blue; */
  }

    .car-name-header{
    margin: 30px 0 0 0 ;
    color:#bbb;
    font-weight: 100;
    font-size:25px;
    padding-left:2px;
    }

    .specs-heading{
      font-size: 50px;
      margin:10px 0 60px 0;
      color:#ddd;
    }

    #spec {
      width:600px;

      height:52px;
      max-height:50px;
      display: flex;
      margin-top:25px;
      margin-left:5px;
      /* border:1px solid red; */
      border-bottom:1px solid #444;
    }

      .spec-title {
        /* border:1px solid #bbb; */
        width:300px;
        font-size: 20px;
        font-weight:300;
        color:#eee;
      }

      .spec-value{
        width:300px;
        height:100px;
        /* border:1px solid blue; */
        float:right;
        text-align: right;
      }

      .spec-value span{
        font-weight: lighter;
        color:#777;
      }


  @media only screen and (max-width: 400px) {
    #static-scrolling-container{
      width:100%;
      height: calc(100vh + 1200px);
      opacity: 0;
      transition: 2s ease-in-out;
    }
    
    #main-showcase-container {
      width:100%;
      height:100%;
      margin: 0 0;
    }
    
      .image-container{
        position: relative;
        
        left:-10vw;
        width:62vw;
        height:50vw;
        margin-top:calc(50vh - 25vw);
      }
    
        #image-full {
          position: relative;
          width:100%;
          max-width:63vw;
          min-width:37.5vw;
          height:60vw;
          background: url('./../../../img/renders/another-full.png');
          background-size: cover;
        }
    
        .image-cut{
          position: absolute;
          width:100%;
          height:60vw;
          background: url('./../../../img/renders/newer-cut.png');
          background-size: cover;
        }
    
    
      #text-container{
        position: absolute;
        right:2vw;
        width:45vw;
        height:40vw;
        min-height:0;
        top:10vw;
      }
    
        .car-description-title{
          font-size:5vw;
          padding-bottom:2vw;
        }
        .car-description{
          font-size:2vw;
          line-height: 1.5em;
        }
    
      #main-specs {
        position: absolute;
        width:0px;
        opacity:0;
        max-width:40vw;
        height:50vw;
        right:1vw;
        top:40vw;
        color:#fff;
      }
    
        .car-name-header{
        margin: 10vw 0 0 0 ;
        color:#bbb;
        font-weight: 100;
        font-size:2vw;
        padding-left:2px;
        }
    
        .specs-heading{
          font-size: 4vw;
          margin:10px 0 4vw 0;
          color:#ddd;
        }
    
        #spec {
          width:100%;
    
          height:5vw;
          max-height:50px;
          margin-top:2vw;
          margin-left:.4vw;
          border-bottom:1px solid #444;
        }
    
          .spec-title {
            width:300px;
            font-size: 2vw;
          }
    
          .spec-value{
            width:300px;
            height:10vw;
            float:right;
            text-align: right;
            font-size:2vw;
          }
    
          .spec-value span{
            font-weight: lighter;
            color:#777;
          }
  }