.cars-container {
  /* height:4000px; */
  width:100%;
  position: relative;
  overflow:hidden; 
}


.minimized-design-showcase{
  position: relative;
  width:100%;
  height:80vw;
  max-height:600px;
}

.minimized-design-image-container{
  position: relative;
  height:65vw;
  max-height:500px;
  width:90%;
  max-width:700px;
  margin:0 auto;
}

.minimized-image-cut{
  position: absolute;
  margin:5vw auto;
  width:100%;
  height:100%;
  background: url('./../../img/renders/newer-cut.png');
  background-size: cover;
}

#minimized-image-full{
  position: absolute;
  margin:5vw auto;
  width:100%;
  height:100%;
  background: url('./../../img/renders/another-full.png');
  background-size: cover;
}

.minimized-car-name{
  color:#fff;
  font-weight: 400;
  font-size: 4vw;
  padding-top: 30vh;
  padding-bottom: 30vh;
  text-align: center;
  
  /* 
  REVERT BACK TO THIS LATER

  color:#fff;
  font-weight: 500;
  font-size:4vw;
  text-align: center;
  padding-top:2vw; */
}

#minimized-main-specs {
  position: relative;
  width:60vw;
  margin:0 auto;
  opacity:1;
  height:50vw;
  color:#fff;
  /* border:2px solid blue; */
}

  #minimized-spec {
    width:60vw;
    position: relative;
    margin:2vw auto;
    height:5vw;
    display: flex;
    border-bottom:1px solid #444;
  }

    .minimized-spec-title {
      /* border:1px solid #bbb; */
      width:30vw;
      font-size: 2vw;
      font-weight:300;
      color:#eee;
    }

    .minimized-spec-value{
      width:30vw;
      height:10vw;
      /* border:1px solid blue; */
      float:right;
      text-align: right;
      font-size:2vw;
    }

    .minimized-spec-value span{
      font-weight: lighter;
      color:#777;
    }


.minimized-subteams{
  width:100%;
  display: flex;
  flex-wrap: wrap;
}

.minimized-subteams-image-container{
  /* border:1px solid red; */
  height:50vw;
  width:55vw;
}

.minimized-subteams-image{
  object-fit: fill;
  width:100%;

}

.minimized-subteams-text-container{
  /* border: 1px solid green; */
  width:40vw;
  padding-left: 2vw;
}
.minimized-subteam-name{
color:#fff;
padding:2vw 0 ;
font-size:5vw;
font-weight:600;
}
.minimized-subteam-description{
  color:#fff;
  font-size:1.5vw;
  font-weight: 100;
  text-align: justify;
  line-height: 1.5em;
}

@media only screen and (max-width: 600px) {
  .cars-container {
  }
  
  
  .minimized-design-showcase{
    position: relative;
    width:100%;
    height:80vw;
    max-height:600px;
  }
  
  .minimized-design-image-container{
    position: relative;
    height:65vw;
    max-height:500px;
    width:90%;
    max-width:700px;
    margin:0 auto;
  }
  
  .minimized-image-cut{
    position: absolute;
    margin:5vw auto;
    width:100%;
    height:100%;
    background: url('./../../img/renders/newer-cut.png');
    background-size: cover;
  }
  
  #minimized-image-full{
    position: absolute;
    margin:5vw auto;
    width:100%;
    height:100%;
    background: url('./../../img/renders/another-full.png');
    background-size: cover;
  }
  
  .minimized-car-name{
    color:#fff;
    font-weight: 500;
    font-size:4vw;
    text-align: center;
    padding-top:2vw;
  }
  
  #minimized-main-specs {
    position: relative;
    width:60vw;
    margin:0 auto;
    opacity:1;
    height:50vw;
    color:#fff;
    /* border:2px solid blue; */
  }
  
    #minimized-spec {
      width:60vw;
      position: relative;
      margin:2vw auto;
      height:5vw;
      display: flex;
      border-bottom:1px solid #444;
    }
  
      .minimized-spec-title {
        /* border:1px solid #bbb; */
        width:30vw;
        font-size: 2vw;
        font-weight:300;
        color:#eee;
      }
  
      .minimized-spec-value{
        width:30vw;
        height:10vw;
        /* border:1px solid blue; */
        float:right;
        text-align: right;
        font-size:2vw;
      }
  
      .minimized-spec-value span{
        font-weight: lighter;
        color:#777;
      }
  
  
  .minimized-subteams{
  }
  
  .minimized-subteams-image-container{
    /* border:1px solid red; */
    height:50vw;
    width:100vw;
    margin:20vw 0 20vw;
  }
  
  .minimized-subteams-image{
    object-fit: fill;
    width:100%;
  
  }
  
  .minimized-subteams-text-container{
    /* border: 1px solid green; */
    width:100vw;
    padding: 5vw;
  }
  .minimized-subteam-name{
  color:#fff;
  padding:2vw 0 ;
  font-size:5vw;
  font-weight:600;
  }
  .minimized-subteam-description{
    color:#fff;
    font-size:4vw;
    font-weight: 100;
    text-align: justify;
    line-height: 1.5em;
  }
}