
$textColor:#fff;
$lightFontWeight:100;

#design-link-container{
  position: relative;
  // border:1px solid red;
  width:100%;
  height:100vh;
  display: flex;
  opacity:0;
  transition:2s ease-out;
}

#design-link-text-container{
  // border:1px solid blue;
  margin-top:200px;
  width:55%;
  padding: 90px 50px 0 200px;
  transition:2s ease-out;
  
}

.design-link-title{
  color:$textColor;
  font-size:70px;
  // border:1px solid red;
}

.design-link-text{
  color:$textColor;
  font-weight: $lightFontWeight;
  line-height: 1.5em;
  margin-top:30px;
  text-align: justify;
  margin-bottom: 40px;
  font-size: 20px;
}

#design-link-image{
  object-fit: cover;
  position: relative;
  width:55%;
  left:50px;
  transition:2s ease-out;
}

@media only screen and (max-width: 1500px) {

  $textColor:#fff;
  $lightFontWeight:100;
  
  #design-link-container{
    width:100%;
    height:55vw;
  }
  
  #design-link-text-container{
    margin-top:20vw;
    width:55%;
    padding: 5vw 3vw 0 10vw;
  }
  
  .design-link-title{
    font-size:5vw;
  }
  
  .design-link-text{
    margin-top:2vw;
    margin-bottom: 2vw;
    font-size: 1.5vw;
  }
  
  #design-link-image{
    object-fit: cover;
    position: relative;
    width:55%;
    left:50px;
    transition:2s ease-out;
  }
}

@media only screen and (max-width: 500px) {
  $textColor:#fff;
  $lightFontWeight:100;
  
  #design-link-container{
    width:100%;
    height:75vw;
  }
  
  #design-link-text-container{
    margin-top:20vw;
    width:55%;
    padding: 2vw 0vw 0 2vw;
  }
  
  .design-link-title{
    font-size:5vw;
  }
  
  .design-link-text{
    margin-top:3vw;
    margin-bottom: 2vw;
    font-size: 2.5vw;
  }
  
  #design-link-image{
    object-fit: cover;
    position: relative;
    width:55%;
    left:50px;
    transition:2s ease-out;
  }
}